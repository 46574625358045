import { render, staticRenderFns } from "./ShareMenu.vue?vue&type=template&id=1f52c222&scoped=true&"
import script from "./ShareMenu.vue?vue&type=script&lang=js&"
export * from "./ShareMenu.vue?vue&type=script&lang=js&"
import style0 from "./ShareMenu.vue?vue&type=style&index=0&id=1f52c222&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f52c222",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCard,VImg,VMenu,VTextField,VTextarea,VTooltip})
